/* page content */
.page {
	font-size: 16px;
	padding-bottom: 50px;
	.news1, .news {
		.date {
			color: #7e5425;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #7e5425;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #7e5425;
		}
	}
	a {
		color: #3a3939;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #7e5425;
			text-decoration: none;
		}
	}
	h1 {
		font-size: 30px;
	}
	.partners {
		margin: 35px 0 0 0;
		.container {
			padding: 50px 30px;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		h1 {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 768px) {
		h1 {
			font-size: 24px;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
	margin-top: 10px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #7e5425;
			line-height: 1;
			@include inline-block;
			a {
				font-size: 14px;
				text-decoration: none;
			}
			&:after {
				content: '\f101';
				font-family: 'fontAwesome';
				font-size: 14px;
				margin: 0px 2px 0px 7px;
				color: #3a3939;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page about */
.certificates {
	.container {
		padding: 50px 30px 25px;
	}
	.certificates__slider {
		margin: 0 -15px;
		.slick-slide {
			outline: none;
			margin: 0 15px;
			img {
				display: block;
				width: 100%;
			}
		}
		.slick-arrow {
			height: 30px;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				font-family: 'fontAwesome';
				font-size: 30px;
				color: #000;
				z-index: 1;
				transition: 400ms;
			}
			&.slick-prev {
				&:after {
					content: '\f104';
				}
			}
			&.slick-next {
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				&:after {
					color: #845b2e;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.certificates__slider {
			.slick-arrow {
				&.slick-prev {
					left: 0;
				}
				&.slick-next {
					right: 0;
				}
			}
		}
	}
}
/* page about */

/* page catalog */
.sidebar {
	width: 255px;
	@include inline-block;
	.sidebar__btn {
		display: none;
	}
	> ul {
		@include ul-default;
		> li {
			> a {
				display: block;
				font-size: 16px;
				text-decoration: none;
				background-color: #ffead3;
				padding: 10px 20px 10px 10px;
				border-bottom: 1px solid #fff;
				line-height: 1.2;
				&:hover {
					background-color: #7e5425;
					color: #fff;
				}
			}
			&.submenu {
				position: relative;
				&:after {
					content: '\f107';
					font-family: 'fontAwesome';
					color: #3a3939;
					font-size: 20px;
					position: absolute;
					top: 10px;
					right: 5px;
					transition: 300ms;
				}
				&:hover, &.active {
					&:after {
						color: #fff;
					}
				}
			}
			&.active > a {
				background-color: #7e5425;
				color: #fff;
			}
			ul {
				display: none;
				@include ul-default;
				li {
					position: relative;
					padding-left: 40px;
					&:after {
						content: '';
						width: 7px;
						height: 7px;
						background-color: #7e5425;
						position: absolute;
						left: 24px;
						top: 50%;
						margin-top: -3.5px;
					}
				    a {
				    	display: block;
						text-decoration: none;
						padding: 8px 0;
						line-height: 1;
						&:hover {
							text-decoration: underline;
						}
					}
					&.active a {
						text-decoration: underline;
						color: #7e5425;
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		display: block;
		width: 100%;
		max-height: 42px;
		overflow: hidden;
		transition: 500ms;
		margin-bottom: 25px;
		&.open {
			max-height: 1500px;
		}
		.sidebar__btn {
			display: block;
			width: 100%;
			text-decoration: none;
			font-size: 16px;
			padding: 10px;
			background: linear-gradient(to top,#794e21,#be9d69);
			color: #fff;
			margin-bottom: 10px;
			position: relative;
			&:after {
				content: '\f0ae';
				position: absolute;
				right: 10px;
				top: 7px;
				font-family: 'fontAwesome';
				font-size: 30px;
				color: #fff;
				transition: 400ms;
			}
			&:hover {
				color: #fff;
			}
			&.active:after {
				transform: rotateY(180deg);
			}
		}
	}
}
.cat {
	width: calc(100% - 290px);
	margin-left: 30px;
	@include inline-block;
	.cat__col {
		display: block;
		min-height: 345px;
		padding: 20px;
		border: 1px solid #d4d4d4;
		margin-bottom: 25px;
		text-decoration: none;
		.cat__col__img {
			overflow: hidden;
		}
		.cat__col__title {
			display: block;
			min-height: 88px;
			font-size: 16px;
			color: #7e5425;
			margin-bottom: 10px;
		}
		.btn {
			width: 100%!important;
			padding: 7px 15px!important;
		}
		&:hover {
			.btn {
				color: #7e5425!important;
				background-color: #fff!important;
			}
		}
	}
	@media screen and (max-width: 991px) {
		display: block;
		width: 100%;
		margin-left: 0;
		.cat__col {
			padding: 15px;
			.cat__col__img {
				img {
					display: block;
					width: 100%;
				}
			}
		}
	}
}
/* page catalog */

/* page unit */
.cat__slider--for {
	width: 100%;
	position: relative;
	border: 1px solid #d4d4d4;
	overflow: hidden;
	&:after {
		content: '\f00e';
		font-family: 'fontAwesome';
		font-size: 32px;
		color: #7e5425;
		position: absolute;
		right: 18px;
		bottom: 18px;
	}
	.slick-slide {
		outline: none;
		img {
			display: block;
			width: 100%;
		}
	}
}

.cat__slider--nav {
	margin: 30px 0;
	padding: 0px 40px;
	.slick-slide {
		height: 84px;
		outline: none;
		overflow: hidden;
		margin: 0 10px;
		border: 1px solid transparent;
		transition: 300ms;
		img {
			display: block;
			width: 100%;	
		}
		&:hover, &.slick-current {
			border: 1px solid #7e5425;
		}
	}
	.slick-arrow {
		height: 30px;
		&:before {
			display: none;
		}
		&:after {
			font-family: 'fontAwesome';
			font-size: 30px;
			color: #000;
			z-index: 1;
			transition: 400ms;
		}
		&.slick-prev {
			left: 15px;
			&:after {
				content: '\f104';
			}
		}
		&.slick-next {
			right: 15px;
			&:after {
				content: '\f105';
			}
		}
		&:hover {
			&:after {
				color: #845b2e;
			}
		}
	}
	@media screen and (max-height: 1199px) {
		.slick-slide {
			height: 65px;
		}
	}
	@media screen and (max-height: 991px) {
		.slick-slide {
			height: 90px;
			img {
				margin: 0 auto;
			}
		}
	}
	@media screen and (max-height: 767px) {
		padding: 0 15px;
		.slick-arrow {
			&.slick-prev {
				left: 0;
			}
			&.slick-next {
				right: 0;
			}
		}
	}
	@media screen and (max-height: 620px) {
		.slick-slide {
			height: 75px;
			img {
				width: auto;
				height: 100%;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 60px;
		}
	}
	@media screen and (max-width: 380px) {
		.slick-slide {
			height: 45px;
		}
	}
}
.cat__unit--right {
	padding-left: 30px;
	@media screen and (max-width: 991px) {
		padding-left: 0;
	}
}
/* page unit */

/* page contacts */
.map {
	margin: 25px 0px 50px;
	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
		> ymaps {
			height: 300px!important;
		}
	}
}
.contacts--list {
	width: 300px;
	@include ul-default;
	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 15px;
		&:after {
			position: absolute;
			left: 0;
			top: 0px;
			font-size: 22px;
			color: #ae8c55;
			font-family: 'fontAwesome';
		}
		&.contacts__location:after {
			content: '\f041';
		}
		&.contacts__phones:after {
			content: '\f095';
		}
		&.contacts__email:after {
			content: '\f0e0';
			font-size: 18px;
			top: 2px;
		}
		a {
			display: block;
			text-decoration: none;
		}
	}
}

.contacts--from {
	display: block;
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		margin-bottom: 10px;
	}
	.btn {
		float: right;
		margin-top: 10px;
	}
}
/* page contacts */

/* page pagination */
.pagination {
	ul {
		@include ul-default;
		li {
			margin-bottom: 10px;
			@include inline-block;
			a {
				text-decoration: none;
				width: 40px;
				height: 40px;
				display: block;
				border: 1px solid #7e5425;
				font-size: 16px;
				text-align: center;
				box-sizing: border-box;
				padding: 11px 2px 0px;
				outline: none;
				line-height: 1;
				&:hover {
					background-color: #7e5425;
					border: 2px solid #7e5425;
					color: #fff;
				}
			}
			&.active {
				a {
					background-color: #7e5425;
					color: #fff;
					border: 1px solid #7e5425;
				}
			}
			&.prev, &.next {
				a {
					position: relative;
					border: 1px solid transparent;
					&:hover {
						border: 1px solid transparent;
						background-color: transparent;
					}
					&:after {
						font-family: 'fontAwesome';
						font-size: 20px;
						color: #000;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						margin-top: -10px;
						text-align: center;
						transition: 400ms;
					}
				}
			}	
			&.prev a {
				&:after {
					content: '\f104';
				} 
			}
			&.next a {
				&:after {
					content: '\f105';
				} 
			}
		}
	}
}
/* page pagination */

/* modal */
.modal {
	.modal-content {
		border-radius: 0px;
		h3 {
			margin: 0;
		}
		.close {
			position: absolute;
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			opacity: 1;
			right: 10px;
			top: 10px;
			outline: none;
			transition: 300ms;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
			padding-bottom: 125px;
		}
		input[type="text"],
		input[type="email"],
		textarea {
			display: block;
			width: 100%;
			font-family: $regular;
			font-size: 16px;
			background-color: #fff;
			padding: 5px 15px;
			border: 1px solid #c6c6c6;
			box-sizing: border-box;
			margin-bottom: 15px;
		}
		.g-recaptcha {
			> div {
				float: right;
			}
		}
		.btn {
			float: right;
			margin-top: 10px;
		}
	}
	&#call--back--modal .modal-dialog {
		width: 420px;
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content h3 {
			font-size: 20px;
		}
		&#call--back--modal .modal-dialog {
			width: auto;
		}
	}
}
/* modal */

.call-btn {
	position: fixed;
	bottom: 100px;
	right: 10%;
	display: table;
	background-color: #845b2e;
	font-family: 'FontAwesome';
	border-radius: 100%;
	text-decoration: none!important;
	width: 95px;
	height: 95px;
	text-align: center;
	padding: 28px 5px 5px 5px;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
	z-index: 1;
	box-sizing: border-box;
	&:hover {
		animation-name: phone;
	  	animation-iteration-count: 1;
	  	animation-duration: 0.7s;
	}
	i {
		display: block;
		font-size: 42px;
		color: #fff;
	}
	&:after, &:before  {
		content: '';
		width: 90%;
		height: 90%;
		background-color: transparent;
		border: 1px solid #fff;
		position: absolute;
		top: 5%;
		left: 5%;
		border-radius: 100%;
		animation-iteration-count: infinite;
	    animation-duration: 1.2s;
	    animation-name: ring;
	}
	&:after {
		animation-delay: 0.6s;
	}
	&:before {
		animation-delay: 0.8s;
	}
	@media screen and (max-width: 1680px) {
		right: 5%;
	}
	@media screen and (max-width: 1440px) {
		right: 1%;
	}
	@media screen and (max-width: 1280px) {
		width: 50px;
		height: 50px;
		padding-top: 13px;
		right: 15px;
		bottom: 30px;
		i {
			font-size: 24px;
		}
	}
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* page content */