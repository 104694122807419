/* footer */
footer {
	background-color: #f2f2f1;
	padding-top: 35px;
	font-size: 14px;
	ul {
		@include ul-default;
		li {
			margin-bottom: 5px;
			&.active {
				a {
					color: #7e5425;
				}
			}
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	h6 {
		margin: 0 0 15px 0	;
	}
	.footer__col {
		&.contacts--col {
			li {
				padding-left: 30px;
				position: relative;
				&:after {
					font-family: 'fontAwesome';
					font-size: 18px;
					position: absolute;
					left: 0;
					top: 2px;
				}
				&.footer__col__location:after {
					content: '\f041';
				}
				&.footer__col__phones {
					a {
						@include inline-block;
					}
					span {
						margin: 0px 4px 0px 10px;
					}
					&:after {
						content: '\f095';
					}
				}
				&.footer__col__whatsapp {
					&:after {
						content: '';
						width: 20px;
						height: 20px;
						background: url(../img/whatsapp.svg) no-repeat center center;
						background-size: contain;
						position: absolute;
						left: -2px;
						top: 0;
					}
				}
				&.footer__col__email:after {
					content: '\f0e0';
					font-size: 16px;
				}
			}
		}
	}
	.container-fluid {
		background-color: #000;
		margin-top: 25px;
		color: #fff;
		padding: 10px 0;
		p {
			font-size: 15px;
			margin: 0;
			@include inline-block;
			&.artmedia {
				float: right;
				position: relative;
				width: 265px;
				padding-right: 130px;
				box-sizing: border-box;
				a {
					position: absolute;
					right: 0;
					top: -4px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 15px;
		.container-fluid {
			margin-top: 10px;
		}
	}
	@media screen and (max-width: 767px) {
		.footer__col {
			margin-bottom: 15px;
			&.contacts--col li.footer__col__phones,
			&.contacts--col li.footer__col__whatsapp {
				a {
					display: block;
				}
				span {
					display: none;
				}
			}
		}
	}
}
/* footer */