@charset "UTF-8";
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
@font-face {
  font-family: 'Conv_GothaProBol';
  src: url("../fonts/GothaProBol.eot");
  src: local("☺"), url("../fonts/GothaProBol.woff") format("woff"), url("../fonts/GothaProBol.ttf") format("truetype"), url("../fonts/GothaProBol.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_GothaProIta';
  src: url("../fonts/GothaProIta.eot");
  src: local("☺"), url("../fonts/GothaProIta.woff") format("woff"), url("../fonts/GothaProIta.ttf") format("truetype"), url("../fonts/GothaProIta.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_GothaProLig';
  src: url("../fonts/GothaProLig.eot");
  src: local("☺"), url("../fonts/GothaProLig.woff") format("woff"), url("../fonts/GothaProLig.ttf") format("truetype"), url("../fonts/GothaProLig.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_GothaProReg';
  src: url("../fonts/GothaProReg.eot");
  src: local("☺"), url("../fonts/GothaProReg.woff") format("woff"), url("../fonts/GothaProReg.ttf") format("truetype"), url("../fonts/GothaProReg.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

*:after, *:before {
  display: block;
  line-height: 1; }

body {
  margin: 0;
  font-family: "Conv_GothaProReg";
  color: #3a3939;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 165px; }
  @media screen and (max-width: 991px) {
    body {
      padding-top: 96px;
      font-size: 16px; } }

h1 {
  font-size: 40px;
  font-family: "Conv_GothaProBol";
  text-transform: uppercase;
  color: #ae8c55;
  display: block;
  text-align: center;
  margin-bottom: 25px;
  line-height: 1;
  text-align: center; }
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 32px; }
      h1 a {
        font-size: 32px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 28px; }
      h1 a {
        font-size: 28px; } }

h2 {
  font-size: 28px;
  font-family: "Conv_GothaProBol";
  color: #343434;
  display: block;
  padding-bottom: 30px;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  text-align: center;
  position: relative; }
  h2:after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto; }
  h2 a {
    font-size: 28px !important;
    text-transform: uppercase;
    font-family: "Conv_GothaProBol";
    color: #343434 !important;
    display: block;
    text-decoration: none !important; }
    h2 a:hover {
      color: #ae8c55 !important; }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
      padding-bottom: 20px;
      margin-bottom: 20px; }
      h2 a {
        font-size: 24px !important; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      padding-bottom: 10px;
      margin-bottom: 15px; }
      h2 a {
        font-size: 22px !important; } }

h3 {
  font-size: 24px;
  font-family: "Conv_GothaProBol";
  color: #343434; }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-size: 20px;
  font-family: "Conv_GothaProBol";
  color: #343434; }

h5 {
  font-size: 18px;
  color: #343434;
  text-transform: uppercase;
  font-family: "Conv_GothaProBol"; }

h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #343434;
  font-family: "Conv_GothaProBol";
  text-transform: uppercase; }

a {
  transition: 350ms;
  color: #3a3939; }
  a:focus {
    color: #3a3939;
    text-decoration: none; }
  a:hover {
    color: #ae8c55; }

input, textarea, button {
  border-radius: 0px;
  -webkit-appearance: none; }

.main, .page {
  position: relative;
  flex: 1 0 auto; }
  .main .container, .page .container {
    padding: 0 30px; }
  .main textarea,
  .main input[type="text"],
  .main input[type="email"], .page textarea,
  .page input[type="text"],
  .page input[type="email"] {
    font-family: "Conv_GothaProReg";
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    padding: 5px 15px; }
  .main a, .main p, .page a, .page p {
    font-size: 16px;
    text-decoration: none; }
  @media screen and (max-width: 1170px) {
    .main .container, .page .container {
      padding: 0 15px; } }

.main .container {
  padding-top: 50px;
  padding-bottom: 50px; }
  @media screen and (max-width: 1170px) {
    .main .container {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .main .container {
      padding-top: 25px;
      padding-bottom: 15px; } }

.btn {
  display: table;
  padding: 7px 45px !important;
  font-size: 16px;
  font-family: "Conv_GothaProBol" !important;
  color: #fff !important;
  background-color: #7e5425 !important;
  cursor: pointer;
  width: auto !important;
  border-radius: 0px;
  border: 2px solid #7e5425;
  text-decoration: none !important;
  line-height: 1;
  transition: 400ms; }
  .btn:focus, .btn:active {
    color: #fff;
    background-color: #7e5425; }
  .btn:hover, .btn.active {
    background-color: #fff !important;
    color: #7e5425 !important; }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    padding-left: 25px;
    position: relative;
    margin-bottom: 5px;
    font-size: 16px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      left: 0;
      top: 2px;
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #7d5325; }
    .check--list li a:hover {
      text-decoration: underline;
      color: #3a3939; }

/* header */
header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #000;
  color: #fff;
  padding-top: 15px;
  font-size: 16px;
  z-index: 9;
  /* header logo */
  /* header logo */
  /* header location */
  /* header location */
  /* header email */
  /* header email */
  /* header phones */
  /* header phones */
  /* header nav */
  /* header nav */
  /* header search */
  /* header search */
  /* header langs */
  /* header langs */ }
  header .container-fluid {
    background-color: #7e5425;
    margin-top: 15px; }
    header .container-fluid .container {
      position: relative; }
  header .logo {
    display: inline-block;
    vertical-align: top; }
  header .location, header .email {
    width: 300px;
    padding-left: 30px;
    position: relative;
    box-sizing: border-box;
    color: #fff;
    margin: 30px 0px 0px 20px;
    display: inline-block;
    vertical-align: top; }
    header .location:after, header .email:after {
      content: '\f041';
      position: absolute;
      left: 0;
      top: 0;
      font-family: 'fontAwesome';
      font-size: 22px; }
  header .email {
    width: 230px; }
  header .email:hover {
    text-decoration: none; }
  header .email:after {
    content: '\f0e0';
    font-size: 18px;
    top: 2px; }
  header .phones {
    margin-top: 0px;
    float: right;
    display: inline-block;
    vertical-align: top; }
    header .phones ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: top;
      margin-left: 10px; }
      header .phones ul li {
        display: block;
        position: relative;
        padding-left: 30px;
        margin: 8px 0;
        line-height: 1; }
        header .phones ul li:after {
          content: '\f095';
          font-family: 'fontAwesome';
          font-size: 22px;
          position: absolute;
          left: 0;
          top: 0; }
        header .phones ul li.whatsapp:after {
          content: '';
          width: 20px;
          height: 20px;
          background-image: url(../img/whatsapp.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center; }
        header .phones ul li a {
          display: block;
          font-size: 18px;
          color: #fff; }
          header .phones ul li a span {
            font-family: "Conv_GothaProBol"; }
          header .phones ul li a:hover {
            text-decoration: none; }
  header .nav {
    display: inline-block;
    vertical-align: top; }
    header .nav ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      header .nav ul > li {
        line-height: 1;
        display: inline-block;
        vertical-align: top; }
        header .nav ul > li a {
          display: block;
          padding: 10px 5px;
          font-size: 17px;
          color: #fff; }
          header .nav ul > li a:hover {
            text-decoration: underline; }
        header .nav ul > li.active a {
          text-decoration: underline; }
  header .nav--btn {
    display: none; }
  header .search {
    display: none;
    position: absolute;
    top: 44px;
    right: 75px;
    width: 250px;
    box-sizing: border-box;
    border: 1px solid #c6c6c6;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2); }
    header .search input {
      display: block;
      width: 100%;
      padding: 7px 15px;
      border: none;
      outline: none; }
  header .search--btn {
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #fff;
    margin: 8px 70px 0px 0px;
    float: right;
    display: inline-block;
    vertical-align: top; }
    header .search--btn:after {
      content: '\f002'; }
    header .search--btn:hover {
      text-decoration: none; }
    header .search--btn.active {
      color: #b7a493; }
  header .langs {
    max-height: 30px;
    position: absolute;
    right: 15px;
    top: 3px;
    padding: 5px 20px 5px 5px;
    transition: 400ms;
    overflow: hidden; }
    header .langs:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 13px;
      right: 2px;
      border-top: 6px solid #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; }
    header .langs ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      header .langs ul li {
        display: block; }
        header .langs ul li a {
          color: #fff;
          text-decoration: underline; }
          header .langs ul li a:hover {
            text-decoration: none; }
        header .langs ul li.active a {
          text-decoration: none; }
    header .langs:hover {
      max-height: 150px;
      background-color: rgba(126, 84, 37, 0.7); }
  @media screen and (max-width: 1199px) {
    header .nav ul > li a {
      padding: 10px 5px; }
    header .email, header .location {
      width: 250px;
      margin-left: 25px; } }
  @media screen and (max-width: 991px) {
    header {
      padding-top: 0px; }
      header .container-fluid {
        margin-top: 0; }
      header .container {
        position: relative; }
      header .logo {
        display: table;
        width: 80px;
        margin: 10px auto; }
        header .logo img {
          display: block;
          width: 100%; }
      header .location {
        display: none; }
      header .email {
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 0;
        color: transparent;
        overflow: hidden;
        position: absolute;
        right: 55px;
        top: 31px; }
        header .email:after {
          color: #fff;
          font-size: 22px; }
      header .phones {
        display: none;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1;
        padding: 10px;
        box-sizing: border-box; }
        header .phones ul {
          display: table;
          margin: 0 auto; }
      header .phones--btn {
        display: block;
        position: absolute;
        right: 19px;
        top: 34px;
        font-size: 25px;
        color: #fff;
        text-decoration: none; }
        header .phones--btn:after {
          content: '\f095';
          font-family: 'fontAwesome'; }
      header .langs {
        right: auto;
        left: 60px;
        top: -65px; }
      header .search--btn {
        position: absolute;
        top: -63px;
        right: 100px;
        margin: 0;
        float: none;
        font-size: 25px; }
      header .search {
        top: -22px; }
      header .nav {
        display: none; }
        header .nav ul > li {
          display: block; }
          header .nav ul > li a {
            display: block;
            padding: 7px 0px; }
      header .nav--btn {
        display: block;
        width: 35px;
        height: 30px;
        position: absolute;
        left: 15px;
        top: -63px;
        padding: 0;
        margin: 0;
        outline: none;
        float: none; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #fff;
          transition: 300ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); } }
  @media screen and (max-width: 768px) {
    header .search--btn,
    header .nav--btn,
    header .langs {
      position: fixed;
      top: 31px; }
    header .search {
      left: 0;
      right: 00;
      margin: 0 auto; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3); }
    .slider .slick-slide img {
      width: 100%;
      height: auto;
      display: block; }
    .slider .slick-slide .slider__block {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin: 0 auto;
      margin-top: -50px;
      text-align: center;
      color: #fff;
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__block__title {
        display: block;
        font-family: "Conv_GothaProBol";
        font-size: 65px;
        color: #fff; }
      .slider .slick-slide .slider__block .slider__block__slogan {
        font-size: 45px; }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      width: 14px;
      height: 14px;
      margin: 0px 6px;
      box-sizing: border-box;
      border: 1px solid #fff; }
      .slider .slick-dots li button {
        display: block;
        background-color: #fff;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transition: 400ms; }
        .slider .slick-dots li button:before {
          display: none; }
      .slider .slick-dots li:hover button, .slider .slick-dots li.slick-active button {
        background-color: #7e5425; }
  @media screen and (max-width: 1440px) {
    .slider .slick-slide .slider__block .slider__block__title {
      font-size: 55px; }
    .slider .slick-slide .slider__block .slider__block__slogan {
      font-size: 35px; } }
  @media screen and (max-width: 1199px) {
    .slider .slick-slide .slider__block .slider__block__title {
      font-size: 45px; }
    .slider .slick-slide .slider__block .slider__block__slogan {
      font-size: 30px; } }
  @media screen and (max-width: 991px) {
    .slider .slick-slide .slider__block .slider__block__title {
      font-size: 38px; }
    .slider .slick-slide .slider__block .slider__block__slogan {
      font-size: 25px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide .slider__block .slider__block__title {
      font-size: 28px; }
    .slider .slick-slide .slider__block .slider__block__slogan {
      font-size: 20px;
      line-height: 1; } }
  @media screen and (max-width: 620px) {
    .slider .slick-slide > img {
      width: auto;
      height: 230px; } }

/* main slider */
/* main production */
.production .container {
  padding-bottom: 0px; }

.production .production__col {
  display: block;
  position: relative;
  text-decoration: none;
  margin-bottom: 25px; }
  .production .production__col .production__col__img {
    overflow: hidden; }
    .production .production__col .production__col__img img {
      display: block;
      width: 100%;
      transition: 300ms; }
  .production .production__col h5 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    margin: 0px 0;
    background-color: rgba(190, 157, 105, 0.7);
    color: #fff;
    text-align: center; }
  .production .production__col .more {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-family: "Conv_GothaProBol";
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 1;
    padding-right: 25px;
    margin-top: -9px;
    z-index: 1; }
    .production .production__col .more:after {
      content: '\f105';
      font-family: 'fontAwesome';
      font-size: 20px;
      position: absolute;
      top: -1px;
      right: 50%;
      margin-right: -85px;
      transition: 400ms; }
  .production .production__col.all {
    height: 255px; }
    .production .production__col.all:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to top, #794e21, #be9d69);
      transition: 500ms; }
  .production .production__col:hover {
    color: #343434; }
    .production .production__col:hover .production__col__img img {
      transform: scale(1.1); }
    .production .production__col:hover .more:after {
      margin-right: -95px; }

@media screen and (max-width: 1199px) {
  .production .production__col img {
    display: block;
    width: 100%; }
  .production .production__col.all {
    height: 212px; } }

@media screen and (max-width: 991px) {
  .production .production__col.all {
    height: 345px; } }

@media screen and (max-width: 767px) {
  .production .production__col.all {
    height: 60px;
    margin-bottom: 0; } }

/* main production */
/* main services */
.services .check--list li {
  margin: 12px 0; }

/* main services */
/* main about */
.about {
  background: #000;
  color: #fff; }
  .about .container {
    padding-bottom: 340px;
    position: relative; }
  .about .about__left {
    position: relative;
    padding-top: 200px;
    margin-right: -15px; }
    .about .about__left img {
      display: block;
      width: 100%; }
    .about .about__left .about__left__block {
      display: table;
      position: absolute;
      top: 65px;
      right: 0;
      background-color: #000;
      padding: 60px; }
      .about .about__left .about__left__block h1 {
        margin: 0 0 15px 0; }
  .about .about__right {
    margin-left: -15px; }
    .about .about__right .about__right__block {
      padding: 105px 0px 25px 78px; }
      .about .about__right .about__right__block .more {
        display: table;
        font-family: "Conv_GothaProBol";
        color: #fff;
        position: relative;
        padding-right: 20px;
        line-height: 1; }
        .about .about__right .about__right__block .more:after {
          content: '\f105';
          font-family: 'fontAwesome';
          font-size: 20px;
          position: absolute;
          top: -1px;
          right: 0;
          transition: 400ms; }
        .about .about__right .about__right__block .more:hover:after {
          right: -15px; }
  .about .about__slider {
    width: 1110px;
    position: absolute;
    bottom: -430px;
    left: 30px; }
    .about .about__slider .slick-slide {
      overflow: hidden;
      outline: none; }
      .about .about__slider .slick-slide img {
        display: block;
        width: 100%; }
    .about .about__slider .slick-arrow {
      width: 80px;
      height: 80px;
      background-color: #fff;
      z-index: 1; }
      .about .about__slider .slick-arrow:before {
        display: none; }
      .about .about__slider .slick-arrow:after {
        font-family: 'fontAwesome';
        font-size: 22px;
        color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -11px;
        text-align: center;
        transition: 400ms; }
      .about .about__slider .slick-arrow.slick-prev:after {
        content: '\f104'; }
      .about .about__slider .slick-arrow.slick-next:after {
        content: '\f105'; }
      .about .about__slider .slick-arrow:hover:after {
        color: #7e5425; }
  @media screen and (min-width: 768px) {
    .about .slick-arrow.slick-next {
      right: 0;
      margin-top: -40px; }
      .about .slick-arrow.slick-next:before {
        content: '';
        display: block;
        width: 50px;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        margin: 0 auto; }
    .about .slick-arrow.slick-prev {
      left: auto;
      right: 0;
      margin-top: 40px; } }
  @media screen and (max-width: 1199px) {
    .about .container {
      padding-bottom: 25px; }
    .about .about__left {
      display: block;
      width: 100%;
      padding-top: 0px; }
      .about .about__left .about__left__block {
        display: block;
        width: 100%;
        position: relative;
        top: 0;
        padding: 15px;
        text-align: center; }
    .about .about__right {
      display: block;
      width: 100%; }
      .about .about__right .about__right__block {
        padding: 25px 0 0 25px; }
    .about .about__slider {
      width: 100%;
      position: relative;
      bottom: 0;
      left: 0;
      margin-top: 25px; } }
  @media screen and (max-width: 991px) {
    .about .about__right > img {
      display: none; }
    .about .about__slider .slick-arrow {
      width: 50px;
      height: 50px; }
      .about .about__slider .slick-arrow.slick-next {
        margin-top: -25px; }
      .about .about__slider .slick-arrow.slick-prev {
        margin-top: 25px; } }
  @media screen and (max-width: 767px) {
    .about .about__slider .slick-arrow {
      width: 35px;
      height: 35px; }
      .about .about__slider .slick-arrow.slick-prev {
        margin-top: 0;
        left: 0; }
      .about .about__slider .slick-arrow.slick-next {
        margin-top: 0;
        right: 0; } }

/* main about */
/* main partners */
.partners {
  background-color: #f2f2f1;
  margin: 510px 0 50px 0; }
  .partners .container {
    padding: 35px 30px; }
  .partners .partners__slider {
    margin: 0 -15px; }
    .partners .partners__slider .slick-slide {
      background-color: #fff;
      margin: 0 15px;
      outline: none;
      overflow: hidden; }
      .partners .partners__slider .slick-slide img {
        transition: 500ms;
        filter: grayscale(100%); }
      .partners .partners__slider .slick-slide:hover img {
        filter: grayscale(0%); }
    .partners .partners__slider .slick-arrow {
      height: 30px;
      z-index: 1; }
      .partners .partners__slider .slick-arrow:before {
        display: none; }
      .partners .partners__slider .slick-arrow:after {
        font-family: 'fontAwesome';
        font-size: 30px;
        color: #000;
        z-index: 1;
        transition: 400ms; }
      .partners .partners__slider .slick-arrow.slick-prev:after {
        content: '\f104'; }
      .partners .partners__slider .slick-arrow.slick-next:after {
        content: '\f105'; }
      .partners .partners__slider .slick-arrow:hover:after {
        color: #845b2e; }
  @media screen and (max-width: 1199px) {
    .partners {
      margin-top: 0; }
      .partners .partners__slider .slick-slide img {
        width: 100%; } }
  @media screen and (max-width: 1170px) {
    .partners .container {
      padding: 25px 15px; } }
  @media screen and (max-width: 991px) {
    .partners {
      margin-bottom: 25px; }
      .partners .partners__slider .slick-arrow.slick-prev {
        left: 10px; }
      .partners .partners__slider .slick-arrow.slick-next {
        right: 10px; } }

/* main partners */
/* main our work */
.our--works {
  font-size: 0; }
  .our--works .our--works__col {
    width: 25%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top; }
    .our--works .our--works__col:after {
      content: '\f00e';
      width: 35px;
      font-family: 'fontAwesome';
      font-size: 35px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin: -17px auto 0;
      color: #fff;
      z-index: 1;
      transition: 400ms;
      opacity: 0; }
    .our--works .our--works__col img {
      display: block;
      width: 100%;
      transition: 500ms; }
    .our--works .our--works__col .more {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font-family: "Conv_GothaProBol";
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 1;
      padding-right: 25px;
      margin-top: -9px;
      z-index: 1; }
      .our--works .our--works__col .more:after {
        content: '\f105';
        font-family: 'fontAwesome';
        font-size: 20px;
        position: absolute;
        top: -1px;
        right: 50%;
        margin-right: -50px;
        transition: 400ms; }
    .our--works .our--works__col:hover img {
      transform: scale(1.1); }
    .our--works .our--works__col:hover .more:after {
      margin-right: -65px; }
    .our--works .our--works__col:hover:after {
      opacity: 1; }
    .our--works .our--works__col.all {
      font-family: "Conv_GothaProBol";
      font-size: 18px; }
      .our--works .our--works__col.all:after {
        display: none; }
      .our--works .our--works__col.all:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to top, #794e21, #be9d69);
        transition: 500ms;
        z-index: 1; }
  @media screen and (max-width: 768px) {
    .our--works .our--works__col {
      width: 50%; }
      .our--works .our--works__col:after {
        opacity: 1; } }
  @media screen and (max-width: 480px) {
    .our--works .our--works__col {
      display: block;
      width: 100%;
      margin-bottom: 15px; } }

/* main our work */
/* main news */
.news .news__col {
  display: block; }
  .news .news__col .news__col__img {
    overflow: hidden; }
    .news .news__col .news__col__img img {
      display: block;
      width: 100%;
      transition: 400ms; }
  .news .news__col .news__col__info {
    padding: 25px 30px;
    transition: 400ms;
    background-color: #f2f2f1; }
    .news .news__col .news__col__info .date {
      display: block;
      font-size: 16px;
      color: #7d7d7c;
      transition: 400ms; }
    .news .news__col .news__col__info h5 {
      text-align: left;
      transition: 0ms; }
    .news .news__col .news__col__info p {
      min-height: 66px; }
  .news .news__col:hover .news__col__img img {
    transform: scale(1.1); }
  .news .news__col:hover .news__col__info {
    background-color: #000; }
    .news .news__col:hover .news__col__info .date {
      color: #fff; }
    .news .news__col:hover .news__col__info h5 {
      text-decoration: underline;
      color: #7e5425; }
    .news .news__col:hover .news__col__info p {
      color: #fff; }

@media screen and (max-width: 991px) {
  .news .news__col .news__col__info {
    padding: 10px 15px; } }

@media screen and (max-width: 767px) {
  .news .news__col {
    margin-bottom: 25px; }
    .news .news__col .news__col__info h5 {
      min-height: 1px; } }

/* main news */
/* main content */
/* page content */
.page {
  font-size: 16px;
  padding-bottom: 50px; }
  .page .news1 .date, .page .news .date {
    color: #7e5425; }
  .page .news1 a, .page .news a {
    text-decoration: underline; }
    .page .news1 a:hover, .page .news a:hover {
      color: #7e5425;
      text-decoration: none; }
  .page .page_nav .active {
    color: #7e5425; }
  .page a {
    color: #3a3939;
    text-decoration: underline;
    transition: 300ms; }
    .page a:active, .page a:focus {
      text-decoration: none; }
    .page a:hover {
      color: #7e5425;
      text-decoration: none; }
  .page h1 {
    font-size: 30px; }
  .page .partners {
    margin: 35px 0 0 0; }
    .page .partners .container {
      padding: 50px 30px; }
  @media screen and (max-width: 1170px) {
    .page {
      font-size: 16px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 991px) {
    .page h1 {
      font-size: 26px; } }
  @media screen and (max-width: 768px) {
    .page h1 {
      font-size: 24px; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 14px;
  margin-top: 10px; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #7e5425;
      line-height: 1;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        font-size: 14px;
        text-decoration: none; }
      .breadcrumbs ul li:after {
        content: '\f101';
        font-family: 'fontAwesome';
        font-size: 14px;
        margin: 0px 2px 0px 7px;
        color: #3a3939;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }

/* breadcrumbs */
/* page about */
.certificates .container {
  padding: 50px 30px 25px; }

.certificates .certificates__slider {
  margin: 0 -15px; }
  .certificates .certificates__slider .slick-slide {
    outline: none;
    margin: 0 15px; }
    .certificates .certificates__slider .slick-slide img {
      display: block;
      width: 100%; }
  .certificates .certificates__slider .slick-arrow {
    height: 30px;
    z-index: 1; }
    .certificates .certificates__slider .slick-arrow:before {
      display: none; }
    .certificates .certificates__slider .slick-arrow:after {
      font-family: 'fontAwesome';
      font-size: 30px;
      color: #000;
      z-index: 1;
      transition: 400ms; }
    .certificates .certificates__slider .slick-arrow.slick-prev:after {
      content: '\f104'; }
    .certificates .certificates__slider .slick-arrow.slick-next:after {
      content: '\f105'; }
    .certificates .certificates__slider .slick-arrow:hover:after {
      color: #845b2e; }

@media screen and (max-width: 991px) {
  .certificates .certificates__slider .slick-arrow.slick-prev {
    left: 0; }
  .certificates .certificates__slider .slick-arrow.slick-next {
    right: 0; } }

/* page about */
/* page catalog */
.sidebar {
  width: 255px;
  display: inline-block;
  vertical-align: top; }
  .sidebar .sidebar__btn {
    display: none; }
  .sidebar > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .sidebar > ul > li > a {
      display: block;
      font-size: 16px;
      text-decoration: none;
      background-color: #ffead3;
      padding: 10px 20px 10px 10px;
      border-bottom: 1px solid #fff;
      line-height: 1.2; }
      .sidebar > ul > li > a:hover {
        background-color: #7e5425;
        color: #fff; }
    .sidebar > ul > li.submenu {
      position: relative; }
      .sidebar > ul > li.submenu:after {
        content: '\f107';
        font-family: 'fontAwesome';
        color: #3a3939;
        font-size: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        transition: 300ms; }
      .sidebar > ul > li.submenu:hover:after, .sidebar > ul > li.submenu.active:after {
        color: #fff; }
    .sidebar > ul > li.active > a {
      background-color: #7e5425;
      color: #fff; }
    .sidebar > ul > li ul {
      display: none;
      list-style: none;
      margin: 0;
      padding: 0; }
      .sidebar > ul > li ul li {
        position: relative;
        padding-left: 40px; }
        .sidebar > ul > li ul li:after {
          content: '';
          width: 7px;
          height: 7px;
          background-color: #7e5425;
          position: absolute;
          left: 24px;
          top: 50%;
          margin-top: -3.5px; }
        .sidebar > ul > li ul li a {
          display: block;
          text-decoration: none;
          padding: 8px 0;
          line-height: 1; }
          .sidebar > ul > li ul li a:hover {
            text-decoration: underline; }
        .sidebar > ul > li ul li.active a {
          text-decoration: underline;
          color: #7e5425; }
  @media screen and (max-width: 991px) {
    .sidebar {
      display: block;
      width: 100%;
      max-height: 42px;
      overflow: hidden;
      transition: 500ms;
      margin-bottom: 25px; }
      .sidebar.open {
        max-height: 1500px; }
      .sidebar .sidebar__btn {
        display: block;
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        padding: 10px;
        background: linear-gradient(to top, #794e21, #be9d69);
        color: #fff;
        margin-bottom: 10px;
        position: relative; }
        .sidebar .sidebar__btn:after {
          content: '\f0ae';
          position: absolute;
          right: 10px;
          top: 7px;
          font-family: 'fontAwesome';
          font-size: 30px;
          color: #fff;
          transition: 400ms; }
        .sidebar .sidebar__btn:hover {
          color: #fff; }
        .sidebar .sidebar__btn.active:after {
          transform: rotateY(180deg); } }

.cat {
  width: calc(100% - 290px);
  margin-left: 30px;
  display: inline-block;
  vertical-align: top; }
  .cat .cat__col {
    display: block;
    min-height: 345px;
    padding: 20px;
    border: 1px solid #d4d4d4;
    margin-bottom: 25px;
    text-decoration: none; }
    .cat .cat__col .cat__col__img {
      overflow: hidden; }
    .cat .cat__col .cat__col__title {
      display: block;
      min-height: 88px;
      font-size: 16px;
      color: #7e5425;
      margin-bottom: 10px; }
    .cat .cat__col .btn {
      width: 100% !important;
      padding: 7px 15px !important; }
    .cat .cat__col:hover .btn {
      color: #7e5425 !important;
      background-color: #fff !important; }
  @media screen and (max-width: 991px) {
    .cat {
      display: block;
      width: 100%;
      margin-left: 0; }
      .cat .cat__col {
        padding: 15px; }
        .cat .cat__col .cat__col__img img {
          display: block;
          width: 100%; } }

/* page catalog */
/* page unit */
.cat__slider--for {
  width: 100%;
  position: relative;
  border: 1px solid #d4d4d4;
  overflow: hidden; }
  .cat__slider--for:after {
    content: '\f00e';
    font-family: 'fontAwesome';
    font-size: 32px;
    color: #7e5425;
    position: absolute;
    right: 18px;
    bottom: 18px; }
  .cat__slider--for .slick-slide {
    outline: none; }
    .cat__slider--for .slick-slide img {
      display: block;
      width: 100%; }

.cat__slider--nav {
  margin: 30px 0;
  padding: 0px 40px; }
  .cat__slider--nav .slick-slide {
    height: 84px;
    outline: none;
    overflow: hidden;
    margin: 0 10px;
    border: 1px solid transparent;
    transition: 300ms; }
    .cat__slider--nav .slick-slide img {
      display: block;
      width: 100%; }
    .cat__slider--nav .slick-slide:hover, .cat__slider--nav .slick-slide.slick-current {
      border: 1px solid #7e5425; }
  .cat__slider--nav .slick-arrow {
    height: 30px; }
    .cat__slider--nav .slick-arrow:before {
      display: none; }
    .cat__slider--nav .slick-arrow:after {
      font-family: 'fontAwesome';
      font-size: 30px;
      color: #000;
      z-index: 1;
      transition: 400ms; }
    .cat__slider--nav .slick-arrow.slick-prev {
      left: 15px; }
      .cat__slider--nav .slick-arrow.slick-prev:after {
        content: '\f104'; }
    .cat__slider--nav .slick-arrow.slick-next {
      right: 15px; }
      .cat__slider--nav .slick-arrow.slick-next:after {
        content: '\f105'; }
    .cat__slider--nav .slick-arrow:hover:after {
      color: #845b2e; }
  @media screen and (max-height: 1199px) {
    .cat__slider--nav .slick-slide {
      height: 65px; } }
  @media screen and (max-height: 991px) {
    .cat__slider--nav .slick-slide {
      height: 90px; }
      .cat__slider--nav .slick-slide img {
        margin: 0 auto; } }
  @media screen and (max-height: 767px) {
    .cat__slider--nav {
      padding: 0 15px; }
      .cat__slider--nav .slick-arrow.slick-prev {
        left: 0; }
      .cat__slider--nav .slick-arrow.slick-next {
        right: 0; } }
  @media screen and (max-height: 620px) {
    .cat__slider--nav .slick-slide {
      height: 75px; }
      .cat__slider--nav .slick-slide img {
        width: auto;
        height: 100%; } }
  @media screen and (max-width: 480px) {
    .cat__slider--nav .slick-slide {
      height: 60px; } }
  @media screen and (max-width: 380px) {
    .cat__slider--nav .slick-slide {
      height: 45px; } }

.cat__unit--right {
  padding-left: 30px; }
  @media screen and (max-width: 991px) {
    .cat__unit--right {
      padding-left: 0; } }

/* page unit */
/* page contacts */
.map {
  margin: 25px 0px 50px; }
  @media screen and (max-width: 991px) {
    .map {
      margin-bottom: 25px; }
      .map > ymaps {
        height: 300px !important; } }

.contacts--list {
  width: 300px;
  list-style: none;
  margin: 0;
  padding: 0; }
  .contacts--list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px; }
    .contacts--list li:after {
      position: absolute;
      left: 0;
      top: 0px;
      font-size: 22px;
      color: #ae8c55;
      font-family: 'fontAwesome'; }
    .contacts--list li.contacts__location:after {
      content: '\f041'; }
    .contacts--list li.contacts__phones:after {
      content: '\f095'; }
    .contacts--list li.contacts__email:after {
      content: '\f0e0';
      font-size: 18px;
      top: 2px; }
    .contacts--list li a {
      display: block;
      text-decoration: none; }

.contacts--from {
  display: block; }
  .contacts--from input[type="text"],
  .contacts--from input[type="email"],
  .contacts--from textarea {
    width: 100%;
    margin-bottom: 10px; }
  .contacts--from .btn {
    float: right;
    margin-top: 10px; }

/* page contacts */
/* page pagination */
.pagination ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .pagination ul li {
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top; }
    .pagination ul li a {
      text-decoration: none;
      width: 40px;
      height: 40px;
      display: block;
      border: 1px solid #7e5425;
      font-size: 16px;
      text-align: center;
      box-sizing: border-box;
      padding: 11px 2px 0px;
      outline: none;
      line-height: 1; }
      .pagination ul li a:hover {
        background-color: #7e5425;
        border: 2px solid #7e5425;
        color: #fff; }
    .pagination ul li.active a {
      background-color: #7e5425;
      color: #fff;
      border: 1px solid #7e5425; }
    .pagination ul li.prev a, .pagination ul li.next a {
      position: relative;
      border: 1px solid transparent; }
      .pagination ul li.prev a:hover, .pagination ul li.next a:hover {
        border: 1px solid transparent;
        background-color: transparent; }
      .pagination ul li.prev a:after, .pagination ul li.next a:after {
        font-family: 'fontAwesome';
        font-size: 20px;
        color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -10px;
        text-align: center;
        transition: 400ms; }
    .pagination ul li.prev a:after {
      content: '\f104'; }
    .pagination ul li.next a:after {
      content: '\f105'; }

/* page pagination */
/* modal */
.modal .modal-content {
  border-radius: 0px; }
  .modal .modal-content h3 {
    margin: 0; }
  .modal .modal-content .close {
    position: absolute;
    width: 25px;
    height: 25px;
    font-family: 'FontAwesome';
    font-size: 25px;
    opacity: 1;
    right: 10px;
    top: 10px;
    outline: none;
    transition: 300ms; }
    .modal .modal-content .close:after {
      content: '\f00d'; }
    .modal .modal-content .close:hover {
      opacity: 0.7; }
  .modal .modal-content form {
    padding-bottom: 125px; }
  .modal .modal-content input[type="text"],
  .modal .modal-content input[type="email"],
  .modal .modal-content textarea {
    display: block;
    width: 100%;
    font-family: "Conv_GothaProReg";
    font-size: 16px;
    background-color: #fff;
    padding: 5px 15px;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
    margin-bottom: 15px; }
  .modal .modal-content .g-recaptcha > div {
    float: right; }
  .modal .modal-content .btn {
    float: right;
    margin-top: 10px; }

.modal#call--back--modal .modal-dialog {
  width: 420px; }

@media screen and (max-width: 991px) {
  .modal .modal-content h3 {
    font-size: 30px; } }

@media screen and (max-width: 768px) {
  .modal .modal-content h3 {
    font-size: 20px; }
  .modal#call--back--modal .modal-dialog {
    width: auto; } }

/* modal */
.call-btn {
  position: fixed;
  bottom: 100px;
  right: 10%;
  display: table;
  background-color: #845b2e;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 95px;
  height: 95px;
  text-align: center;
  padding: 28px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  z-index: 1;
  box-sizing: border-box; }
  .call-btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call-btn i {
    display: block;
    font-size: 42px;
    color: #fff; }
  .call-btn:after, .call-btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #fff;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring; }
  .call-btn:after {
    animation-delay: 0.6s; }
  .call-btn:before {
    animation-delay: 0.8s; }
  @media screen and (max-width: 1680px) {
    .call-btn {
      right: 5%; } }
  @media screen and (max-width: 1440px) {
    .call-btn {
      right: 1%; } }
  @media screen and (max-width: 1280px) {
    .call-btn {
      width: 50px;
      height: 50px;
      padding-top: 13px;
      right: 15px;
      bottom: 30px; }
      .call-btn i {
        font-size: 24px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* page content */
/* footer */
footer {
  background-color: #f2f2f1;
  padding-top: 35px;
  font-size: 14px; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin-bottom: 5px; }
      footer ul li.active a {
        color: #7e5425; }
      footer ul li a:hover {
        text-decoration: none; }
  footer h6 {
    margin: 0 0 15px 0; }
  footer .footer__col.contacts--col li {
    padding-left: 30px;
    position: relative; }
    footer .footer__col.contacts--col li:after {
      font-family: 'fontAwesome';
      font-size: 18px;
      position: absolute;
      left: 0;
      top: 2px; }
    footer .footer__col.contacts--col li.footer__col__location:after {
      content: '\f041'; }
    footer .footer__col.contacts--col li.footer__col__phones a {
      display: inline-block;
      vertical-align: top; }
    footer .footer__col.contacts--col li.footer__col__phones span {
      margin: 0px 4px 0px 10px; }
    footer .footer__col.contacts--col li.footer__col__phones:after {
      content: '\f095'; }
    footer .footer__col.contacts--col li.footer__col__whatsapp:after {
      content: '';
      width: 20px;
      height: 20px;
      background: url(../img/whatsapp.svg) no-repeat center center;
      background-size: contain;
      position: absolute;
      left: -2px;
      top: 0; }
    footer .footer__col.contacts--col li.footer__col__email:after {
      content: '\f0e0';
      font-size: 16px; }
  footer .container-fluid {
    background-color: #000;
    margin-top: 25px;
    color: #fff;
    padding: 10px 0; }
    footer .container-fluid p {
      font-size: 15px;
      margin: 0;
      display: inline-block;
      vertical-align: top; }
      footer .container-fluid p.artmedia {
        float: right;
        position: relative;
        width: 265px;
        padding-right: 130px;
        box-sizing: border-box; }
        footer .container-fluid p.artmedia a {
          position: absolute;
          right: 0;
          top: -4px; }
  @media screen and (max-width: 991px) {
    footer {
      padding-top: 15px; }
      footer .container-fluid {
        margin-top: 10px; } }
  @media screen and (max-width: 767px) {
    footer .footer__col {
      margin-bottom: 15px; }
      footer .footer__col.contacts--col li.footer__col__phones a,
      footer .footer__col.contacts--col li.footer__col__whatsapp a {
        display: block; }
      footer .footer__col.contacts--col li.footer__col__phones span,
      footer .footer__col.contacts--col li.footer__col__whatsapp span {
        display: none; } }

/* footer */
