/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}
		img {
			width: 100%;
			height: auto;
			display: block;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin: 0 auto;
			margin-top: -50px;
			text-align: center;
			color: #fff;
			z-index: 1;
			.slider__block__title {
				display: block;
				font-family: $bold;
				font-size: 65px;
				color: #fff;
			}
			.slider__block__slogan {
				font-size: 45px;
			}
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			width: 14px;
			height: 14px;
			margin: 0px 6px;
			box-sizing: border-box;
			border: 1px solid #fff;
			button {
				display: block;
				background-color: #fff;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				transition: 400ms;
				&:before {
					display: none;
				}
			}
			&:hover, &.slick-active {
				button {
					background-color: #7e5425;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 55px;
				}
				.slider__block__slogan {
					font-size: 35px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 45px;
				}
				.slider__block__slogan {
					font-size: 30px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 38px;
				}
				.slider__block__slogan {
					font-size: 25px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			.slider__block {
				.slider__block__title {
					font-size: 28px;
				}
				.slider__block__slogan {
					font-size: 20px;
					line-height: 1;
				}
			}
		}
	}
	@media screen and (max-width: 620px) {
		.slick-slide {
			> img {
				width: auto;
				height: 230px;
			}
		}
	}
}
/* main slider */

/* main production */
.production {
	.container {
		padding-bottom: 0px;
	}
	.production__col {
		display: block;
		position: relative;
		text-decoration: none;
		margin-bottom: 25px;
		.production__col__img {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				transition: 300ms;
			}
		}
		h5 {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 10px;
			margin: 0px 0;
			background-color: rgba(190, 157, 105, 0.7);
			color: #fff;
			text-align: center;
		}
		.more {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			font-family: $bold;
			font-size: 18px;
			color: #fff;
			text-align: center;
			line-height: 1;
			padding-right: 25px;
			margin-top: -9px;
			z-index: 1;
			&:after {
				content: '\f105';
				font-family: 'fontAwesome';
				font-size: 20px;
				position: absolute;
				top: -1px;
				right: 50%;
				margin-right: -85px;
				transition: 400ms;
			}
		}
		&.all {
			height: 255px;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(to top, #794e21, #be9d69);
				transition: 500ms;
			}
		}
		&:hover {
			color: #343434;
			.production__col__img {
				img {
					transform: scale(1.1);
				}
			}
			.more:after {
				margin-right: -95px;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.production__col {
			img {
				display: block;
				width: 100%;
			}
			&.all {
				height: 212px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.production__col {
			&.all {
				height: 345px;
			}
		}
	}	
	@media screen and (max-width: 767px) {
		.production__col {
			&.all {
				height: 60px;
				margin-bottom: 0;
			}
		}
	}	
}
/* main production */

/* main services */
.services {
	.check--list {
		li {
			margin: 12px 0;
		}
	}
}
/* main services */

/* main about */
.about {
	background: #000;
	color: #fff;
	.container {
		padding-bottom: 340px;
		position: relative;
	}
	.about__left {
		position: relative;
		padding-top: 200px;
		margin-right: -15px;
		img {
			display: block;
			width: 100%;
		}
		.about__left__block {
			display: table;
			position: absolute;
			top: 65px;
			right: 0;
			background-color: #000;
			padding: 60px;
			h1 {
				margin: 0 0 15px 0;
			}
		}
	}
	.about__right {
		margin-left: -15px;
		.about__right__block {
			padding: 105px 0px 25px 78px;
			.more {
				display: table;
				font-family: $bold;
				color: #fff;
				position: relative;
				padding-right: 20px;
				line-height: 1;
				&:after {
					content: '\f105';
					font-family: 'fontAwesome';
					font-size: 20px;
					position: absolute;
					top: -1px;
					right: 0;
					transition: 400ms;
				}
				&:hover {
					&:after {
						right: -15px;
					}
				}
			}
		}
	}
	.about__slider {
		width: 1110px;
		position: absolute;
		bottom: -430px;
		left: 30px;
		.slick-slide {
			overflow: hidden;
			outline: none;
			img {
				display: block;
				width: 100%;
			}
		}
		.slick-arrow {
			width: 80px;
			height: 80px;
			background-color: #fff;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				font-family: 'fontAwesome';
				font-size: 22px;
				color: #000;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin-top: -11px;
				text-align: center;
				transition: 400ms;
			}
			&.slick-prev {
				&:after {
					content: '\f104';
				}
			}
			&.slick-next {
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				&:after {
					color: #7e5425;
				}
			}
		}
	}
	@media screen and (min-width: 768px) {
		.slick-arrow {
			&.slick-next {
				right: 0;
				margin-top: -40px;
				&:before {
					content: '';
					display: block;
					width: 50px;
					height: 1px;
					background-color: #000;
					position: absolute;
					bottom: -1px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
			&.slick-prev {
				left: auto;
				right: 0;
				margin-top: 40px;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.container {
			padding-bottom: 25px;
		}
		.about__left {
			display: block;
			width: 100%;
			padding-top: 0px;
			.about__left__block {
				display: block;
				width: 100%;
				position: relative;
				top: 0;
				padding: 15px;
				text-align: center;
			}
		}
		.about__right {
			display: block;
			width: 100%;
			.about__right__block {
				padding: 25px 0 0 25px;
			}
		}
		.about__slider {
			width: 100%;
			position: relative;
			bottom: 0;
			left: 0;
			margin-top: 25px;
		}
	}
	@media screen and (max-width: 991px) {
		.about__right {
			> img {
				display: none;
			}
		}
		.about__slider {
			.slick-arrow {
				width: 50px;
				height: 50px;
				&.slick-next {
					margin-top: -25px;
				}
				&.slick-prev {
					margin-top: 25px;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.about__slider {
			.slick-arrow {
				width: 35px;
				height: 35px;
				&.slick-prev {
					margin-top: 0;
					left: 0;
				}
				&.slick-next {
					margin-top: 0;
					right: 0;
				}
			}
		}
	}
}
/* main about */

/* main partners */
.partners {
	background-color: #f2f2f1;
	margin: 510px 0 50px 0;
	.container {
		padding: 35px 30px;
	}
	.partners__slider {
		margin: 0 -15px;
		.slick-slide {
			background-color: #fff;
			margin: 0 15px;
			outline: none;
			overflow: hidden;
			img {
				transition: 500ms;
				filter: grayscale(100%);
			}
			&:hover img {
				filter: grayscale(0%);
			}
		}
		.slick-arrow {
			height: 30px;
			z-index: 1;
			&:before {
				display: none;
			}
			&:after {
				font-family: 'fontAwesome';
				font-size: 30px;
				color: #000;
				z-index: 1;
				transition: 400ms;
			}
			&.slick-prev {
				&:after {
					content: '\f104';
				}
			}
			&.slick-next {
				&:after {
					content: '\f105';
				}
			}
			&:hover {
				&:after {
					color: #845b2e;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		margin-top: 0;
		.partners__slider {
			.slick-slide img {
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		.container {
			padding: 25px 15px;
		}
	}
	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
		.partners__slider .slick-arrow {
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
}
/* main partners */

/* main our work */
.our--works {
	font-size: 0;
	.our--works__col {
		width: 25%;
		overflow: hidden;
		position: relative;
		@include inline-block;
		&:after {
			content: '\f00e';
			width: 35px;
			font-family: 'fontAwesome';
			font-size: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			margin: -17px auto 0;
			color: #fff;
			z-index: 1;
			transition: 400ms;
			opacity: 0;
		}
		img {
			display: 	block;
			width: 100%;
			transition: 500ms;
		}
		.more {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			font-family: $bold;
			font-size: 18px;
			color: #fff;
			text-align: center;
			line-height: 1;
			padding-right: 25px;
			margin-top: -9px;
			z-index: 1;
			&:after {
				content: '\f105';
				font-family: 'fontAwesome';
				font-size: 20px;
				position: absolute;
				top: -1px;
				right: 50%;
				margin-right: -50px;
				transition: 400ms;
			}
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
			.more:after {
				margin-right: -65px;
			}
			&:after {
				opacity: 1;
			}
		}
		&.all {
			font-family: $bold;
			font-size: 18px;
			&:after {
				display: none;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(to top, #794e21, #be9d69);
				transition: 500ms;
				z-index: 1;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.our--works__col {
			width: 50%;
			&:after {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.our--works__col {
			display: block;
			width: 100%;
			margin-bottom: 15px;
		}
	}
}
/* main our work */

/* main news */
.news {
	.news__col {
		display: block;
		.news__col__img {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				transition: 400ms;
			}
		}
		.news__col__info {
			padding: 25px 30px;
			transition: 400ms;
			background-color: #f2f2f1;
			.date {
				display: block;
				font-size: 16px;
				color: #7d7d7c;
				transition: 400ms;
			}
			h5 {
				text-align: left;
				transition: 0ms;
			}
			p {
				min-height: 66px;
			}
		}
		&:hover {
			.news__col__img img {
				transform: scale(1.1);
			}
			.news__col__info {
				background-color: #000;
				.date {
					color: #fff;
				}
				h5 {
					text-decoration: underline;
					color: #7e5425;
				}
				p {
					color: #fff;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col {
			.news__col__info {
				padding: 10px 15px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.news__col {
			margin-bottom: 25px;
			.news__col__info h5 {
				min-height: 1px;
			}
		}
	}
}
/* main news */


/* main content */