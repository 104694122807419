/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
	font-family: 'Conv_GothaProBol';
	src: url('../fonts/GothaProBol.eot');
	src: local('☺'), 
		url('../fonts/GothaProBol.woff') format('woff'), 
		url('../fonts/GothaProBol.ttf') format('truetype'), 
		url('../fonts/GothaProBol.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_GothaProIta';
	src: url('../fonts/GothaProIta.eot');
	src: local('☺'), 
		url('../fonts/GothaProIta.woff') format('woff'), 	
		url('../fonts/GothaProIta.ttf') format('truetype'), 
		url('../fonts/GothaProIta.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_GothaProLig';
	src: url('../fonts/GothaProLig.eot');
	src: local('☺'), 
		url('../fonts/GothaProLig.woff') format('woff'), 
		url('../fonts/GothaProLig.ttf') format('truetype'), 
		url('../fonts/GothaProLig.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_GothaProReg';
	src: url('../fonts/GothaProReg.eot');
	src: local('☺'), 
		url('../fonts/GothaProReg.woff') format('woff'), 
		url('../fonts/GothaProReg.ttf') format('truetype'), 
		url('../fonts/GothaProReg.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}	

$regular: 'Conv_GothaProReg';
$bold: 'Conv_GothaProBol';
$light: 'Conv_GothaProLig';
$italic: 'Conv_GothaProIta';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}
*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	margin: 0;
	font-family: $regular;
	color: #3a3939;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding-top: 165px;
	@media screen and (max-width: 991px) {
		padding-top: 96px;
		font-size: 16px;
	}
}

h1 {
	font-size: 40px;
	font-family: $bold;
	text-transform: uppercase;
	color: #ae8c55;
	display: block;
	text-align: center;
	margin-bottom: 25px;
	line-height: 1;
	text-align: center;
	@media screen and (max-width: 991px) {
		font-size: 32px;
		a {
			font-size: 32px;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
		a {
			font-size: 28px;
		}
	}
}
h2 {
	font-size: 28px;
	font-family: $bold;
	color: #343434;
	display: block;
	padding-bottom: 30px;
	margin: 0 0 30px 0;
	text-transform: uppercase;
	text-align: center;
	position: relative;
	&:after {
		content: '';
		display: block;
		width: 30px;
		height: 2px;
		background-color: #000;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
	}
	a {
		font-size: 28px!important;
		text-transform: uppercase;
		font-family: $bold;
		color: #343434!important;
		display: block;
		text-decoration: none!important;
		&:hover {
			color: #ae8c55!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 24px;
		padding-bottom: 20px;
		margin-bottom: 20px;
		a {
			font-size: 24px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 22px;
		padding-bottom: 10px;
		margin-bottom: 15px;
		a {
			font-size: 22px!important;
		}
	}
}
h3 {
	font-size: 24px;
	font-family: $bold;
	color: #343434;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
}
h4 {
	font-size: 20px;
	font-family: $bold;
	color: #343434;
}
h5 {
	font-size: 18px;
	color: #343434;
	text-transform: uppercase;
	font-family: $bold;
}
h6 {
	font-size: 16px;
	text-transform: uppercase;
	color: #343434;
	font-family: $bold;
	text-transform: uppercase;
}
a {
	transition: 350ms;
	color: #3a3939;
	&:focus {
		color: #3a3939;
		text-decoration: none;
	}
	&:hover {
		color: #ae8c55;
	}
}
input, textarea, button {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	.container {
		padding: 0 30px;
	}
	textarea, 
	input[type="text"],
	input[type="email"] {
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		border: 1px solid #c6c6c6;
		padding: 5px 15px;
	}
	a,p {
		font-size: 16px;
		text-decoration: none;
	}
	@media screen and (max-width: 1170px) {
		.container {
			padding: 0 15px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	padding: 7px 45px!important;
	font-size: 16px;
	font-family: $bold!important;
	color: #fff!important;
	background-color: #7e5425!important;
	cursor: pointer;
	width: auto!important;
	border-radius: 0px;
	border: 2px solid #7e5425;
	text-decoration: none!important;
	line-height: 1;
	transition: 400ms;
	&:focus, &:active {
		color: #fff;
		background-color: #7e5425;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #7e5425!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		padding-left: 25px;
		position: relative;
		margin-bottom: 5px;
		font-size: 16px;
		&:before {
			content: '\f00c';
			position: absolute;
			left: 0;
			top: 2px;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #7d5325;
		}
		a {
			&:hover {
				text-decoration: underline;
				color: #3a3939;
			}
		}
	}
}

/* header */
header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background-color: #000;
	color: #fff;
	padding-top: 15px;
	font-size: 16px;
	z-index: 9;
	.container-fluid {
		background-color: #7e5425;
		margin-top: 15px;
		.container {
			position: relative;
		}
	}

	/* header logo */
	.logo {
		@include inline-block;
	}
	/* header logo */

	/* header location */
	.location, .email {
		width: 300px;
		padding-left: 30px;
		position: relative;
		box-sizing: border-box;
		color: #fff;
		margin: 30px 0px 0px 20px;
		@include inline-block;
		&:after {
			content: '\f041';
			position: absolute;
			left: 0;
			top: 0;
			font-family: 'fontAwesome';
			font-size: 22px;
		}
	}
	.email {
		width: 230px;
	}
	/* header location */

	/* header email */
	.email {
		&:hover {
			text-decoration: none;
		}
		&:after {
			content: '\f0e0';
			font-size: 18px;
			top: 2px;
		}
	}
	/* header email */

	/* header phones */
	.phones {
		margin-top: 0px;
		float: right;
		@include inline-block;
		ul {
			@include ul-default;
			@include inline-block;
			margin-left: 10px;
			li {
				display: block;
				position: relative;
				padding-left: 30px;
				margin: 8px 0;
				line-height: 1;
				&:after {
					content: '\f095';
					font-family: 'fontAwesome';
					font-size: 22px;
					position: absolute;
					left: 0;
					top: 0;
				}
				&.whatsapp {
					&:after {
						content: '';
						width: 20px;
						height: 20px;
						background-image: url(../img/whatsapp.svg);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center center;
					}
				}
				a {
					display: block;
					font-size: 18px;
					color: #fff;
					span {
						font-family: $bold;
					}
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	/* header phones */

	/* header nav */
	.nav {
		@include inline-block;
		ul {
			@include ul-default;
			> li {
				line-height: 1;
				@include inline-block;
				a {
					display: block;
					padding: 10px 5px;
					font-size: 17px;
					color: #fff;
					&:hover {
						text-decoration: underline;
					}
				}
				&.active a {
					text-decoration: underline;
				}
			}
		}
	}
	.nav--btn {
		display: none;
	}
	/* header nav */

	/* header search */
	.search {
		display: none;
		position: absolute;
		top: 44px;
		right: 75px;
		width: 250px;
		box-sizing: border-box;
		border: 1px solid #c6c6c6;
		box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
		input {
			display: block;
			width: 100%;
			padding: 7px 15px;
			border: none;
			outline: none;
		}
	}
	.search--btn {
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #fff;
		margin: 8px 70px 0px 0px;
		float: right;
		@include inline-block;
		&:after {
			content: '\f002';
		}
		&:hover {
			text-decoration: none;
		}
		&.active {
			color: #b7a493;
		}
	}
	/* header search */

	/* header langs */
	.langs {
		max-height: 30px;
		position: absolute;
		right: 15px;
		top: 3px	;
		padding: 5px 20px 5px 5px;
		transition: 400ms;
		overflow: hidden;
		&:after {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			top: 13px;
			right: 2px;
			border-top: 6px solid #fff;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
		}
		ul {
			@include ul-default;
			li {
				display: block;
				a {
					color: #fff;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
				&.active {
					a {
						text-decoration: none;
					}
				}
			}
		}
		&:hover {
			max-height: 150px;
			background-color: rgba(126, 84, 37, 0.7);
		}
	}
	/* header langs */
	@media screen and (max-width: 1199px) {
		.nav ul > li a {
			padding: 10px 5px;
		}

		.email, .location {
			width: 250px;
			margin-left: 25px;
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 0px;
		.container-fluid {
			margin-top: 0;
		}
		.container {
			position: relative;
		}

		.logo {
			display: table;
			width: 80px;
			margin: 10px auto;
			img {
				display: block;
				width: 100%;
			}
		}

		.location {
			display: none;
		}

		.email {
			width: 25px;
			height: 25px;
			padding: 0;
			margin: 0;
			color: transparent;
			overflow: hidden;
			position: absolute;
			right: 55px;
			top: 31px;
			&:after {
				color: #fff;
				font-size: 22px;
			}
		}

		.phones {
			display: none;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			margin: 0;
			text-align: center;
			background-color: rgba(0,0,0,0.8);
			z-index: 1;
			padding: 10px;
			box-sizing: border-box;
			ul {
				display: table;
				margin: 0 auto;
			}
		}
		.phones--btn {
			display: block;
			position: absolute;
			right: 19px;
			top: 34px;
			font-size: 25px;
			color: #fff;
			text-decoration: none;
			&:after {
				content: '\f095';
				font-family: 'fontAwesome';
			}
		}

		.langs {
			right: auto;
			left: 60px;
			top: -65px;
		}

		.search--btn {
			position: absolute;
			top: -63px;
			right: 100px;
			margin: 0;
			float: none;
			font-size: 25px;
		}
		.search {
			top: -22px;
		}
	
		.nav {
			display: none;
			ul > li {
				display: block;
				a {
					display: block;
					padding: 7px 0px;
				}
			}
		}
		.nav--btn {
			display: block;
			width: 35px;
			height: 30px;
			position: absolute;
			left: 15px;
			top: -63px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 300ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.search--btn,
		.nav--btn,
		.langs {
			position: fixed;
			top: 31px;
		}
		.search {
			left: 0;
			right: 00;
			margin: 0 auto;
		}
	}
}
/* header */